<template>
    <section class="invoice-management">
        <div class="top-bar bg-white">
            <el-button @click="handleReset" size="small" icon="el-icon-refresh" style="margin-right: 10px"></el-button>
            <el-input v-model="formSearch.leasorName" placeholder="请输入租户名" style="margin: 0 5px; width: 200px" size="small"/>
            <el-input v-model="formSearch.apartmentName" placeholder="请输入房间名" style="margin: 0 5px; width: 200px" size="small"/>
            <el-input v-model="formSearch.comment" placeholder="请输入账单备注" style="margin: 0 5px; width: 200px" size="small"/>
            <span style="margin: 0 5px;">
                <el-radio-group v-model="formSearch.type" size="small">
                    <el-radio-button :label="700001">收据</el-radio-button>
                    <el-radio-button :label="700002">普票</el-radio-button>
                </el-radio-group>
            </span>
            <span style="margin: 0 5px;">
                <el-radio-group v-model="formSearch.status" size="small">
                    <el-radio-button label="待开票"/>
                    <el-radio-button label="已开票"/>
                </el-radio-group>
            </span>
            <el-button type="primary" size="small" @click="handleSearch">筛选</el-button>
            <el-button type="primary" size="small" icon="el-icon-download" @click="aaa">批量开票</el-button>
            <el-button type="primary" size="small" icon="el-icon-download">导出发票</el-button>
        </div>
        <r-e-table class="bg-white" ref="orderTableRef" :dataRequest="getInvoiceList" :columns="tableColumn"
                   :query="formSearch" tooltip-effect="light" :height="790" :row-style="rowStyle">
            <template slot="empty">
                <el-empty/>
            </template>
            <el-table-column slot="toolbar" label="操作" width="200">
                <template slot-scope="{ row }">
                    <div class="table-tools">
                        <el-button size="mini" v-if="row.statusCode===0 && getButtonMenu('MAKE_INVOICCE_BUTTON')" @click="invoiceAudit(row)">开票</el-button>
                        <!--<el-button size="mini">已开</el-button>-->
                    </div>
                </template>
            </el-table-column>
        </r-e-table>
        <!--        :dataRequest="getVoucherTableData"-->
        <!--        :data="[{uuid : 123}]"-->
        <layer-invoice ref="layerInvoice"/>
    </section>
</template>

<script>
    import {tableColumn} from "@/views/bill-management/invoice-management/data";
    import {getInvoiceList, invoicePrint, invoiceAudit} from "@/api/invoice-management";
    import {MessageSuccess} from "@custom/message";
    import {buttonCookies} from "@storage/cookies";
    export default {
        name: "invoice-management",
        data() {
            return {
                tableColumn: tableColumn(this),
                formSearch: {},
            };
        },
        components: {
            layerInvoice: () => import("./components/layer-invoice"),
        },
        mounted() {

        },
        methods: {
            // 列表信息
            getInvoiceList(params) {
                return getInvoiceList(params);
            },
            // 筛选
            handleSearch() {
                // this.$refs["orderTableRef"].pNumber = 1;
                this.$refs["orderTableRef"].getTableData();
            },
            // 刷新按钮
            handleReset() {
                this.formSearch = {};
                Promise.resolve(this.formSearch).then(() => this.handleSearch());
            },
            // 收据打印
            invoicePrint(data) {
                let {uuid} = data;
                const loading = this.$loading({lock: true, text: "请您稍等...", spinner: "el-icon-loading", background: "rgba(0, 0, 0, 0.7)"});
                invoicePrint(uuid).then(res => {
                    if (res) {
                        const binaryData = [];
                        binaryData.push(res);
                        let pdfUrl = window.URL.createObjectURL(new Blob(binaryData, {type: 'application/pdf'}));
                        let tempwindow = window.open("", '_blank');
                        tempwindow.location = pdfUrl;
                    }
                    loading.close();
                }).catch(err => {
                    loading.close();
                });
            },
            // 开票按钮
            invoiceAudit(data) {
                let {uuid} = data;
                this.$confirm('此操作将给该记录开票,是否继续?', '注意', {
                    type: 'warning'
                }).then(async () => {
                    const loading = this.$loading({lock: true, text: 'Loading', spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.7)'});
                    invoiceAudit(uuid).then(res => {
                        MessageSuccess("开票成功");
                        loading.close();
                        this.handleSearch();
                    }).catch(err => {
                        loading.close();
                    });
                }).catch(() => {
                });
            },
            getButtonMenu(menu){
                return JSON.parse(buttonCookies.get()).map(item=>item.url).some(v => v === menu);
            },
            // 表格样式
            rowStyle({row, rowIndex}){
                let {type} = row;
                type = Number(type);
                if(type===700001) return {backgroundColor:'#FFF5F5'};
                if(type===700002) return {backgroundColor:'#E5EFFF'};
                return {};
            },
            // 批量开票
            aaa(){
                this.$refs["layerInvoice"].openDialog();
            },
        }
    }
</script>

<style lang="scss" scoped>
    .invoice-management {
        padding: VH(15px) VW(15px);

        .top-bar {
            display: flex;
            margin: VH(10px) 0 VH(25px);
            padding: VH(15px) VW(15px);
            justify-content: flex-start;
        }

        /deep/ .r-e-table {
            .el-table .cell {
                //white-space: nowrap;
                text-align: center;
            }
        }
    }
</style>
